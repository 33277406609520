<template>
  <div class="indexBox">
    <div style="display: flex; background-color: #f7f8fa; width: 100%">
      <div style="background-color: #fff; padding-right: 10px">
        <div style="width: 100%">
          <div style="width: 100%">
            <div style="margin-bottom: 10px">
              <el-button
                :style="[
                  {
                    backgroundColor: nowChooseMonthIndex == 0 ? '#01b37c' : '',
                  },
                  { color: nowChooseMonthIndex == 0 ? '#fff' : '' },
                ]"
                @click="lastMonthFn"
                >上个月</el-button
              >
              <el-button
                :style="[
                  {
                    backgroundColor: nowChooseMonthIndex == 1 ? '#01b37c' : '',
                  },
                  { color: nowChooseMonthIndex == 1 ? '#fff' : '' },
                ]"
                @click="nowMonthFn"
                >当前月</el-button
              >
              <el-button
                :style="[
                  {
                    backgroundColor: nowChooseMonthIndex == 2 ? '#01b37c' : '',
                  },
                  { color: nowChooseMonthIndex == 2 ? '#fff' : '' },
                ]"
                @click="nextMonthFn"
                >下个月</el-button
              >
            </div>
          </div>

          <!-- 当月日期表 -->
          <div style="width: 100%; display: flex; flex-wrap: wrap">
            <div
              @click="chooseToday(item)"
              v-for="(item, index) in everyDay"
              :key="index"
              :style="[
                { width: '100px' },
                { height: '60px' },
                {
                  backgroundColor:
                    index + 1 == nowToday ? '#EAF9F4' : '#f9f9f9',
                },
                { marginRight: '10px' },
                { marginBottom: '10px' },
                { textAlign: 'center' },
                { cursor: 'pointer' },
              ]"
            >
              <div style="margin-top: 10px">
                {{ item.month }} 月{{ item.day }}日
              </div>
              <div>星期{{ item.weekNum }}</div>
            </div>
          </div>
        </div>

        <!-- 上左 -->
        <div style="width: 100%; display: flex; align-items: center">
          <span>门店: </span>
          <el-select
            clearable
            v-model="searchForm.StoreId"
            style="margin-left: 10px"
            placeholder="请选择门店"
          >
            <el-option
              v-for="item in AllDoorData"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>

          <el-select
            clearable
            filterable
            v-model="searchForm.TeachId"
            style="margin-left: 20px"
            placeholder="请选择教师"
          >
            <el-option
              v-for="item in AllTeacherData"
              :key="item.NickName"
              :label="item.NickName"
              :value="item.NickName"
            >
            </el-option>
          </el-select>

          <!-- <span style="margin-left: 20px">教师: </span>

          <el-input
            style="width: 200px; margin-left: 10px"
            v-model="form.TeachId"
            placeholder="请输入教师"
          /> -->
          <el-select
            clearable
            filterable
            v-model="searchForm.Classtype"
            style="margin-left: 20px"
            placeholder="请选择分类名称"
          >
            <el-option
              v-for="item in PlanTypeData"
              :key="item.Id"
              :label="item.label"
              :value="item.Id"
            >
            </el-option>
          </el-select>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #01b37c;
              color: #fff;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="SearchFn"
          >
            搜索
          </div>

          <div
            style="
              width: 80px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background-color: #fff;
              color: #01b37c;
              border-radius: 5px;
              font-size: 16px;
              margin-left: 20px;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
            @click="ResetFn"
          >
            重置
          </div>
        </div>

        <!-- 当天列表 -->
        <div style="margin-top: 20px">
          <el-table
            :data="ThisWeekData"
            :header-cell-style="getRowClass"
            style="width: 100%; background-color: #fff"
            :row-style="{ height: '60px' }"
          >
            <el-table-column label="序号" prop="CardId">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="课程名称" prop="WorkName" /> -->
            <el-table-column label="分类名称" prop="WorkTypeName">
              <!-- <template slot-scope="scope">
                <span v-if="scope.row.WorkType == 1">基础课</span>
                <span v-if="scope.row.WorkType == 2">专项课</span>
                <span v-if="scope.row.WorkType == 3">试听课</span>
              </template> -->
            </el-table-column>
            <!-- <el-table-column label="学生批次" prop="CardId" /> -->
            <el-table-column label="门店" prop="StoreName" />
            <el-table-column label="课室" prop="StoreRoomName" />
            <el-table-column label="教师名称" prop="TeacherName" />
            <el-table-column label="课程教案" prop="PlanDetailInfo.FileName" />
            <!-- <el-table-column label="课室" prop="CardNum" /> -->
            <el-table-column label="上课时间" prop="WorkTime" />
            <!-- <el-table-column label="课程状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.WorkType == 0">未审批</span>
            <span v-if="scope.row.WorkType == 1">已通过</span>
            <span v-if="scope.row.WorkType == 2">未通过</span>
          </template>
        </el-table-column> -->
            <el-table-column label="课程状态" prop="ClassStatus">
              <template slot-scope="scope">
                <span v-if="scope.row.ClassStatus == 0">未开课</span>
                <span v-if="scope.row.ClassStatus == 1">已开课</span>
                <span v-if="scope.row.ClassStatus == 2">已结束</span>
              </template>
            </el-table-column>
            <el-table-column label="报名学生" prop="StuNum" />
            <!-- <el-table-column label="审批状态" prop="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.Status == 0">未审批</span>
            <span v-if="scope.row.Status == 1">已通过</span>
            <span v-if="scope.row.Status == 2">未通过</span>
          </template>
        </el-table-column> -->

            <!-- <el-table-column label="操作">
          <template #default="scope">
            <span @click="openEditPeoPleNum(scope.row)" style="font-size: 14px;color: #007AFF;cursor: pointer;">修改人数</span>
            <span v-if="scope.row.Status == 2" @click="openRejectTc(scope.row)" style="font-size: 14px;color: #EE6E6E;cursor: pointer;margin-left: 10px;">
              驳回理由
            </span>
          </template>
        </el-table-column> -->
          </el-table>
        </div>

        <!-- 排课 隐藏 -->
        <div
          v-if="false"
          style="
            width: 1110px;
            background-color: #fff;
            display: flex;
            margin-top: 20px;
            min-height: 750px;
          "
        >
          <div style="width: 1130px; margin-right: 10px">
            <div
              style="
                max-width: 1110px;
                height: 80px;
                display: flex;
                align-items: center;
                background-color: #f5f5f5;
              "
            >
              <div
                @click="clickWeekFn(index)"
                v-for="(item, index) in AllWeekData"
                :key="index"
                :style="[
                  { color: weekIndex == index ? '#333' : '#333' },
                  { marginRight: index == 6 ? '0px' : '10px' },
                  { width: '150px' },
                  { textAlign: 'center' },
                  { cursor: 'pointer' },
                ]"
              >
                <div>{{ item.name }}</div>
                <div style="margin-top: 5px">{{ item.date }}</div>
              </div>
            </div>

            <div
              v-if="AllWeekData.length > 0"
              style="max-width: 1110px; display: flex"
            >
              <div
                style="margin-right: 10px; width: 150px"
                v-for="(item, index) in AllWeekData"
                :key="index"
              >
                <div
                  v-for="(val, index) in item.courseData"
                  class="coursebox"
                  :style="[
                    {
                      backgroundColor:
                        val.myClassCheck == true ? '#EAF9F4' : '#f9f9f9',
                    },
                  ]"
                  :key="index"
                >
                  <div v-if="val.StartHour">
                    {{
                      val.StartHour < 10 ? "0" + val.StartHour : val.StartHour
                    }}:{{
                      val.StartMinute < 10
                        ? "0" + val.StartMinute
                        : val.StartMinute
                    }}
                    -
                    {{ val.EndHour < 10 ? "0" + val.EndHour : val.EndHour }}:{{
                      val.EndMinute < 10 ? "0" + val.EndMinute : val.EndMinute
                    }}
                  </div>
                  <div v-if="val.PlanDetailInfo" style="margin-top: 7px">
                    {{ val.PlanDetailInfo.FileName || "无" }}
                  </div>
                  <div style="margin-top: 10px">
                    {{ val.WorkName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加课程 -->
      <div style="flex: 1; background-color: #fff; margin-left: 10px">
        <div
          style="
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ececec;
            box-sizing: border-box;
            padding: 0 20px;
            box-sizing: border-box;
          "
        >
          <div style="font-size: 16px; font-weight: bold">我的课程</div>
          <div
            style="
              width: 90px;
              height: 32px;
              border-radius: 5px;
              line-height: 32px;
              text-align: center;
              color: #fff;
              font-size: 14px;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="openDialogVisible1"
          >
            申请课程
          </div>
        </div>

        <div
          style="
            padding: 10px 0 10px 10px;
            box-sizing: border-box;
            width: 100%;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
          "
        >
          <div
            v-for="(item, index) in MineWeekData"
            :key="index"
            style="margin-top: 20px"
          >
            <div style="font-size: 18px; color: #333">{{ item.WorkTime }}</div>
            <div style="display: flex; flex-wrap: wrap; width: 100%">
              <div
                style="
                  margin-top: 10px;
                  width: 190px;
                  height: 240px;
                  background-color: #eaf9f4;
                  padding: 20px;
                  box-sizing: border-box;
                  margin-right: 10px;
                "
              >
                <div style="font-size: 14px; color: #333; font-weight: bold">
                  <span>门店：</span>
                  <span>{{ item.StoreName }}</span>
                </div>
                <div
                  style="
                    font-size: 14px;
                    color: #666;
                    font-weight: 500;
                    margin-top: 10px;
                  "
                >
                  <span
                    >{{ item.StartHour }}:{{
                      item.StartMinute == 0
                        ? item.StartMinute + "0"
                        : item.StartMinute
                    }}
                    - {{ item.EndHour }}:{{
                      item.EndMinute == 0
                        ? item.EndMinute + "0"
                        : item.EndMinute
                    }}</span
                  >
                </div>
                <div
                  style="
                    font-size: 14px;
                    color: #333;
                    font-weight: bold;
                    margin-top: 10px;
                  "
                >
                  <!-- <span>课程：</span>
                  <span>{{ item.WorkName }}</span> -->
                </div>
                <div style="margin-top: 20px">教案：</div>
                <div
                  v-if="item.PlanDetailInfo"
                  style="font-size: 14px; color: #01b37c; margin-top: 10px"
                >
                  {{ item.PlanDetailInfo.FileName }}
                </div>

                <div style="margin-top: 20px; display: flex">
                  <div
                    style="
                      width: 68px;
                      height: 24px;
                      line-height: 24px;
                      text-align: center;
                      font-size: 14px;
                      color: #fff;
                      background-color: #01b37c;
                      border-radius: 4px;
                      margin-left: 25px;
                      cursor: pointer;
                    "
                    @click="openLookWorkFn(item)"
                  >
                    查看教案
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="false" style="display: flex; padding: 20px 0; width: 100%">
      <el-select clearable v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="value"
        style="margin-left: 20px"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        clearable
        v-model="value"
        style="margin-left: 20px"
        placeholder="请选择分类名称"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #01b37c;
          color: #fff;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
        "
      >
        搜索
      </div>
      <div
        style="
          width: 80px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #fff;
          color: #01b37c;
          border-radius: 5px;
          font-size: 16px;
          margin-left: 20px;
          border: 1px solid #01b37c;
          box-sizing: border-box;
        "
      >
        重置
      </div>
    </div>

    <div v-if="false" style="width: 100%">
      <el-table
        :data="
          MineWeekData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
        "
        :header-cell-style="getRowClass"
        style="width: 100%"
        :row-style="{ height: '60px' }"
      >
        <el-table-column label="序号" prop="CardId">
          <template slot-scope="scope">
            <span>{{ (pageIndex - 1) * pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column label="学生人数" prop="StuNum" />
        <el-table-column label="时间" prop="WorkTime" />
        <el-table-column label="课程" prop="WorkName" />
        <el-table-column label="门店" prop="StoreName" />
        <el-table-column label="教案" prop="PlanDetailInfo.FileName" />
        <!-- <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm
                style="margin-left: 10px"
                confirm-button-text='删除'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确认删除吗？"
                @confirm="deleteKeys"
            >
              <el-button slot="reference" type="danger" @click="handleDelete(scope)">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column> -->
      </el-table>

      <div style="margin-top: 10px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="AllCount"
        >
        </el-pagination>
      </div>
    </div>

    <el-drawer
      size="100%"
      :title="'查看教案/作业'"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div style="width: 100%; padding: 30px; box-sizing: border-box">
        <div
          style="
            margin-bottom: 20px;
            font-size: 18px;
            color: #333;
            display: flex;
            align-items: center;
            margin-top: 20px;
          "
        >
          教案图片
          <!-- <div style="margin-left: 20px">
            <el-button @click="printImg2" size="small" type="primary"
              >多张打印</el-button
            >
          </div> -->
        </div>
        <div v-if="WorkItem.PlanDetailInfo">
          <img
            style="margin-right: 10px"
            v-for="(item, index) in WorkItem.PlanDetailInfo.PictureUrl"
            :key="index"
            :src="item"
            alt=""
          />
        </div>

        <div
          style="
            margin-bottom: 20px;
            font-size: 18px;
            color: #333;
            display: flex;
            align-items: center;
            margin-top: 20px;
          "
        >
          作业
          <div style="margin-left: 20px">
            <el-button @click="printImg" size="small" type="primary"
              >打印作业</el-button
            >
          </div>
        </div>
        <img v-if="drawer" :src="WorkItem.PlanDetailInfo.HomeWorkUrl" alt="" />

        <div
          style="
            margin-bottom: 20px;
            font-size: 18px;
            color: #333;
            display: flex;
            align-items: center;
            margin-top: 20px;
          "
        >
          文件
          <el-upload
            style="margin-left: 20px"
            class="upload-demo"
            action="http://175.178.60.146:8084/api/Auth/UploadFile"
            :on-change="handleChange"
            :file-list="fileList"
            :show-file-list="true"
            :headers="headers"
            :on-preview="Previewf"
            disabled
          >
          </el-upload>
        </div>
      </div>
    </el-drawer>

    <!-- 申请课程 -->
    <el-dialog title="申请课程" :visible.sync="dialogVisible1" width="500px">
      <div
        style="
          padding: 0 40px;
          box-sizing: border-box;
          width: 100%;
          background-color: #fff;
        "
      >
        <div>
          <!-- 分类名称 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择类型
            </div>
            <el-select
              clearable
              filterable
              v-model="form.WorkType"
              style="flex: 1"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in PlanTypeData"
                :key="item.Id"
                :label="item.label"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择小教案 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择教案
            </div>
            <el-select
              v-if="false"
              clearable
              v-model="form.TeachPlanId"
              style="flex: 1"
              placeholder="请选择教案"
            >
              <el-option
                v-for="item in SmallTeachData"
                :key="item.Id"
                :label="
                  item.Year +
                  '岁' +
                  item.Month +
                  '月' +
                  item.Week +
                  '周' +
                  ' (' +
                  item.FileName +
                  ' )'
                "
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <el-cascader
              :props="{ expandTrigger: 'hover' }"
              style="flex: 1"
              v-model="form.TeachPlanId"
              :options="teachData"
              :show-all-levels="true"
              @change="handleChange"
            ></el-cascader>
          </div>

          <!-- 选择学生 -->
          <div
            v-if="false"
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择学生
            </div>
            <div style="flex: 1; display: flex; justify-content: space-between">
              <el-select
                clearable
                v-model="form.Year"
                style="width: 32%"
                placeholder="选择岁"
              >
                <el-option
                  v-for="item in YearData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                v-model="form.Month"
                style="width: 32%"
                placeholder="选择月"
              >
                <el-option
                  v-for="item in MonthData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
              <el-select
                clearable
                v-model="form.Week"
                style="width: 32%"
                placeholder="选择周"
              >
                <el-option
                  v-for="item in WeekData"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <!-- 学生人数 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              学生人数
            </div>
            <el-input
              style="flex: 1"
              v-model="form.StuNum"
              placeholder="请输入学生人数"
            />
          </div>

          <!-- 选择日期 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择日期
            </div>
            <el-date-picker
              style="flex: 1"
              v-model="form.WorkTime"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <!-- 选择时间 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择时间
            </div>
            <el-time-select
              style="flex: 1"
              v-model="form.Time"
              :picker-options="{
                start: '06:00',
                step: '00:01',
                end: '23:00',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
            <span style="margin: 0 5px">至</span>
            <el-time-select
              style="flex: 1"
              v-model="form.Time2"
              :picker-options="{
                start: '06:00',
                step: '00:01',
                end: '23:00',
              }"
              placeholder="选择时间"
            >
            </el-time-select>
          </div>

          <!-- 选择门店 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择门店
            </div>
            <el-select
              clearable
              filterable
              v-model="form.StoreId"
              style="flex: 1"
              placeholder="请选择门店"
            >
              <el-option
                v-for="item in AllDoorData"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择课室 -->
          <div
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择课室
            </div>
            <el-select
              clearable
              filterable
              v-model="form.StoreRoomName"
              style="flex: 1"
              placeholder="请选择课室"
            >
              <el-option
                v-for="item in oneStoreData.Rooms"
                :key="item.Name"
                :label="item.Name"
                :value="item.Name"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择课程 -->
          <div
            v-if="false"
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择课程
            </div>
            <el-select
              clearable
              v-model="form.WorkId"
              style="flex: 1"
              placeholder="请选择课程"
            >
              <el-option
                v-for="item in courseData"
                :key="item.Id"
                :label="item.WorkName"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- 选择教案 -->
          <div
            v-if="false"
            style="
              display: flex;
              width: 100%;
              align-items: center;
              margin-top: 10px;
            "
          >
            <div style="font-size: 16px; font-size: 16px; width: 80px">
              选择教案
            </div>
            <el-select
              clearable
              v-model="form.BigTeachPlanId"
              style="flex: 1"
              placeholder="请选择教案"
            >
              <el-option
                v-for="item in teachData"
                :key="item.Id"
                :label="item.TeachPlanName"
                :value="item.Id"
              >
              </el-option>
            </el-select>

            <span
              @click="SearchSmallIdFn"
              style="margin-left: 5px; color: #01b37c; cursor: pointer"
              >查询</span
            >
          </div>
        </div>

        <div
          style="
            margin-top: 40px;
            display: flex;
            width: 100%;
            justify-content: center;
          "
        >
          <div
            @click="offDialogVisible1"
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #01b37c;
              background-color: #fff;
              border: 1px solid #01b37c;
              box-sizing: border-box;
              cursor: pointer;
            "
          >
            取消
          </div>
          <div
            style="
              width: 120px;
              height: 40px;
              border-radius: 5px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              background-color: #01b37c;
              margin-left: 20px;
              cursor: pointer;
            "
            @click="AddExamineFn"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetAllTeachPlan,
  GetAllUser,
  GetAllWork,
  AddExamine,
  GetAllClassMeByTime,
  GetAllClassByTime, //这一周
  GetOwnStore,
  GetRealTeachPlanBySome,
  GetAllStore,
  GetOneStore,
  GetAllUserName,
  GetAllTeachPlanByOne,
} from "@/request/api.js";

export default {
  inject: ["reload"],
  data() {
    return {
      nowChooseMonthIndex: 1,
      drawer: false,
      direction: "ltr",
      fileList: [],
      tableData: [],
      teachData: [],
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      //打印测试
      printUrl: "",
      printUrl2: "",
      someJSONdata: [
        {
          name: "John Doe",
          email: "john@doe.com",
          phone: "111-111-1111",
        },
        {
          name: "Barry Allen",
          email: "barry@flash.com",
          phone: "222-222-2222",
        },
        {
          name: "Cool Dude",
          email: "cool@dude.com",
          phone: "333-333-3333",
        },
      ],

      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      options2: [
        {
          value: 1,
          label: "二级菜单",
          children: [
            {
              value: 11,
              label: "北京",
              children: [
                {
                  value: 11,
                  label: "南宁",
                },
                {
                  value: 12,
                  label: "佛山",
                },
              ],
            },
            {
              value: 12,
              label: "上海",
            },
          ],
        },
      ],
      weekIndex: 0,
      AllWeekData: [
        // {
        //   name: "时间",
        //   Id: 0,
        //   date: "",
        // },
        {
          name: "星期一",
          Id: 1,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期二",
          Id: 2,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期三",
          Id: 3,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期四",
          Id: 4,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期五",
          Id: 5,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期六",
          Id: 6,
          date: "2023-03-01",
          courseData: [],
        },
        {
          name: "星期日",
          Id: 7,
          date: "2023-03-07",
          courseData: [],
        },
      ],

      YearData: [], //年份
      MonthData: [], //月份
      WeekData: [
        {
          Id: 1,
          Name: "第一周",
        },
        {
          Id: 2,
          Name: "第二周",
        },
        {
          Id: 3,
          Name: "第三周",
        },
        {
          Id: 4,
          Name: "第四周",
        },
      ],
      dialogVisible1: false, //添加课程
      searchForm: {
        Classtype: "",
        TeachId: "",
        StoreId: "",
      }, //搜索条件
      form: {
        Year: "",
        Month: "",
        WorkTime: "",
        Date: "",
        Time: "",
        TeachPlanId: "",
        WorkId: "",
        Time2: "",
        StartTime: "2023-01-01",
        EndTime: "2023-04-01",
        monday: "",
        sunday: "",
        StoreRoomName: "",
        Classtype: "",
        StoreId: "",
        TeachId: "",
        StoreId: "",
      },
      AllDoorData: [], //所有门店
      courseData: [], //课程数据
      WorkTypeData: [
        {
          Id: 1,
          Name: "基础课",
        },
        {
          Id: 2,
          Name: "专项课",
        },
        {
          Id: 3,
          Name: "试听课",
        },
      ],
      SmallTeachData: [], //小教案数据
      MineWeekData: [],
      ThisWeekData: [], //本周课程
      dates: [], //日期
      pageIndex: 1,
      pageSize: 10,
      AllCount: 0,
      StoreData: [], //门店课室
      WorkItem: [], //教案作业信息
      searchCheck: false,
      month: "",
      nowToday: "",
      everyDay: [],
      oneStoreData: {},
      AllTeacherData: [],
      PlanTypeData: [],
      teachData: [],
    };
  },

  mounted() {
    let date = new Date();
    let month = date.getMonth();
    let nowToday = date.getDate();
    this.nowToday = nowToday;
    // this.getEveryDay(date, month);
    this.month = month + 1;
    this.GetAllClassMeByTimeFn(); //获取自己一周要上的课程
    this.GetAllClassByTimeFn({ month: month + 1, day: nowToday, weekNum: "" });
    this.GetAllStoreFn();
  },

  created() {
    let that = this;
    this.GetAllUserNameFn();
    // this.GetAllUserFn();//获取教室列表
    this.GetAllTeachPlanFn(); //获取所有教案
    this.GetAllWorkFn(); //获取所有课程
    // this.GetAllClassByTimeFn(); //获取这一周课程
    this.GetOwnStoreFn(); //获取门店课室

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    this.getNum(year, month);

    let arr = [];
    let arr2 = [];
    for (let i = 0; i < 7; i++) {
      arr.push({
        Id: i,
        Name: i + "岁",
      });
    }
    for (let i = 1; i < 13; i++) {
      arr2.push({
        Id: i,
        Name: i + "月",
      });
    }
    this.YearData = arr;
    this.MonthData = arr2;

    // this.getWeektime3();

    //date 你想要传的日期,格式为YYYY-MM-DD
    // var today = new Date();
    // this.weeks(today);

    //一周的时间
    // var weeks = this.weeks(today);
    // console.log("weeks", weeks);

    //筛选日期
    // var weekToDay = weeks.filter((item) => {
    //   return item == today;
    // });
    // console.log("weekToDay", weekToDay);
    // console.log("today", today);
  },

  watch: {
    //监听
    "form.StartTime"(val) {
      console.log("val", val);
      // this.form.StartTime = "";
      let today = new Date(val);
      this.weeks(today);
      this.getWeektime4();
    },
    "form.EndTime"(val) {
      console.log("val", val);
      // this.form.EndTime = "";
      let today = new Date(val);
      this.weeks(today);
      this.getWeektime5();
    },

    "form.Year"(e) {
      // if (e != "") {
      // if (this.form.Month != "" && this.form.Week != "") {
      this.GetRealTeachPlanBySomeFn();
      // }
      // }
    },
    "form.Month"(e) {
      // if (e != "") {
      // if (this.form.Year != "" && this.form.Week != "") {
      this.GetRealTeachPlanBySomeFn();
      // }
      // }
    },
    "form.Week"(e) {
      // if (e != "") {
      // if (this.form.Year != "" && this.form.Money != "") {
      this.GetRealTeachPlanBySomeFn();
      // }
      // }
    },

    "form.StoreId"(e) {
      this.form.StoreRoomName = "";
      this.GetOneStoreFn();
    },

    "searchForm.StoreId"() {
      this.GetAllClassByTimeFn();
    },

    "searchForm.TeachId"() {
      this.GetAllClassByTimeFn();
    },

    "searchForm.Classtype"() {
      this.GetAllClassByTimeFn();
    },

    "form.WorkType"(e) {
      if (e != "") {
        this.GetAllTeachPlanByOneFn();
      } else {
        this.teachData = [];
      }
    },

    "form.TeachPlanId"(e) {
      console.log("教案id==>", e);
    },
  },

  methods: {
    //教案

    getNum(year, month) {
      let dates = [];
      const days = new Date(year, month, 0).getDate(); //返回这个月的总天数
      const weekDays = ["日", "一", "二", "三", "四", "五", "六"];
      for (let i = 1; i <= days; i++) {
        dates.push({
          // week: '星期'+ weekDays[new Date(year+'/'+month+'/'+`${i}`).getDay()],
          day: i,
          month: month,
          weekNum:
            weekDays[new Date(year + "/" + month + "/" + `${i}`).getDay()],
        });
      }
      console.log("新dates==>", dates);
      this.everyDay = dates;
      return dates;
    },

    lastMonthFn() {
      this.nowChooseMonthIndex = 0;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
      this.GetAllClassMeByTimeFn(); //获取自己一天要上的课程
    },
    nowMonthFn() {
      this.nowChooseMonthIndex = 1;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
      this.GetAllClassMeByTimeFn(); //获取自己一天要上的课程
    },
    nextMonthFn() {
      this.nowChooseMonthIndex = 2;
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 2;
      this.month = month;
      this.getNum(year, month);
      this.GetAllClassByTimeFn();
      this.GetAllClassMeByTimeFn(); //获取自己一天要上的课程
    },

    handleChange(e) {
      console.log("ee==>", e);
      this.form.TeachPlanId = e[e.length - 1];
      console.log("this.form.TeachPlanId==>", this.form.TeachPlanId);
    },

    //获取所有分类
    GetAllTeachPlanFn() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案分类==>", res.Data);
          // that.teachData = res.Data;
          that.PlanTypeData = res.Data;
        }
      });
    },

    //根据id获取教案分类详情   /api/TeachPlan/GetAllTeachPlanByOne
    GetAllTeachPlanByOneFn() {
      let that = this;
      GetAllTeachPlanByOne({ id: that.form.WorkType }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("根据id获取教案分类详情==>", res.Data);
          // let tree = that.teachData;

          let arr = [];

          //获取所有教案循环  res.Data.length
          for (let i = 0; i < res.Data.children.length; i++) {
            //每个教案
            let element = res.Data.children[i];
            // console.log("element.children==>", element.children);

            arr[i] = element;

            if (element.children === null) {
              // console.log("空");
              element.children = [];
            }
            if (element.teachPlans === null) {
              // console.log("空");
              element.teachPlans = [];
            }

            for (let h = 0; h < element.children.length; h++) {
              const element3 = element.children[h];
              console.log("element3==>", element3);
              for (let k = 0; k < element3.children.length; k++) {
                const element4 = element3.children[k];

                if (element4.fatherId != null) {
                  for (let j = 0; j < element4.teachPlans.length; j++) {
                    const element5 = element4.teachPlans[j];
                    console.log("element5==>", element5);
                    element4.children.push({
                      label: element5.FileName,
                      // children: [],
                      AddTime: element5.AddTime,
                      FileName: element5.FileName,
                      HomeWorkUrl: element5.HomeWorkUrl,
                      Id: element5.Id,
                      value: element5.Id,
                      PictureUrl: element5.PictureUrl,
                      PlanName: element5.PlanName,
                    });
                  }
                }
                console.log("element4==>", element4);
              }
              for (let k = 0; k < element3.teachPlans.length; k++) {
                const element4 = element3.teachPlans[k];
                console.log("element4==>", element4);
                element3.children.push({
                  label: element4.FileName,
                  // children: [],
                  AddTime: element4.AddTime,
                  FileName: element4.FileName,
                  HomeWorkUrl: element4.HomeWorkUrl,
                  Id: element4.Id,
                  value: element4.Id,
                  PictureUrl: element4.PictureUrl,
                  PlanName: element4.PlanName,
                });
                //   for (let m = 0; m < element4.teachPlans.length;m++) {
                //   const element5 = element4.teachPlans[m];
                //   element4.children.push({
                //     label: element5.FileName,
                //     children: [],
                //     AddTime: element5.AddTime,
                //     FileName: element5.FileName,
                //     HomeWorkUrl: element5.HomeWorkUrl,
                //     Id: element5.Id,
                //     PictureUrl: element5.PictureUrl,
                //     PlanName: element5.PlanName,
                //   });
                // }
              }

              if (element3.children === null) {
                // console.log("空");
                element3.children = [];
              }
              if (element3.teachPlans === null) {
                // console.log("空");
                element3.teachPlans = [];
              }
              //将element5.teachPlans循环放入element5.children

              // for (let p = 0; p < element3.children.length; p++) {
              //   const element5 = element3.children[p];
              //   console.log("element5==>", element5);

              // }
            }

            for (let j = 0; j < element.teachPlans.length; j++) {
              const element2 = element.teachPlans[j];

              // console.log("element2==>", element2);
              element.children.push({
                label: element2.FileName,
                // children: [],
                AddTime: element2.AddTime,
                FileName: element2.FileName,
                HomeWorkUrl: element2.HomeWorkUrl,
                Id: element2.Id,
                value: element2.Id,
                PictureUrl: element2.PictureUrl,
                PlanName: element2.PlanName,
              });
            }

            // return
            // tree[Year].treeData[Month].children[Week].children.push({
            //   label: element.FileName,
            //   children: [],
            //   AddTime: element.AddTime,
            //   FileName: element.FileName,S
            //   HomeWorkUrl: element.HomeWorkUrl,
            //   Year: element.Year,
            //   Month: element.Month,
            //   Week: element.Week,
            //   Id: element.Id,
            //   PictureUrl: element.PictureUrl,
            //   Id2: "-1",
            // });
          }

          that.teachData = arr;

          // let arr10 = [];
          for (let kkk = 0; kkk < res.Data.teachPlans.length; kkk++) {
            const element10 = res.Data.teachPlans[kkk];
            that.teachData.push({
              Id: element10.Id,
              AddTime: element10.AddTime,
              label: element10.FileName,
              FileName: element10.FileName,
              value: element10.Id,
              PlanId: element10.PlanId,
              PlanName: element10.PlanName,
              HomeWorkUrl: element10.HomeWorkUrl,
              PictureUrl: element10.PictureUrl,
              children: element10.children,
              teachPlans: element10.FileName,
            });
          }

          // that.teachData.push({
          //   Id: "",
          //   label:'一级教案',
          //   value:'一级教案',
          //   children: res.Data.teachPlans,
          //   label: '一级教案',
          //   teachPlans: res.Data.teachPlans,
          //   isLeaf: 0,
          //   fatherId: res.Data.Id,
          // });

          that.$nextTick(() => {
            that.teachData = JSON.parse(JSON.stringify(that.teachData)); // 深拷贝
          });

          console.log("that.teachData ===>", that.teachData);
          console.log("data2 ===>", that.data2);
          console.log("arr ===>", arr);
        }
      });
    },

    // 获取教师列表
    GetAllUserNameFn() {
      let that = this;
      GetAllUserName().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取教师列表==>", res.Data);

          that.AllTeacherData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    //获取某个门店的信息
    GetOneStoreFn() {
      let that = this;
      GetOneStore({ id: this.form.StoreId }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取某个门店的信息==>", res.Data);
          that.oneStoreData = res.Data;
        } else {
          that.$message.error(res.Message);
        }
      });
    },

    //  // 获取所有门店
    GetAllStoreFn() {
      let that = this;
      GetAllStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有门店==>", res.Data);
          that.AllDoorData = res.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    monthChange(date) {
      this.getEveryDay(date, date.getMonth());
    },
    // 获取当月每一天
    getEveryDay(date, month) {
      console.log("date", date);
      //设置月份
      date.setMonth(month + 1);
      //设置一个月的某一天-这里设置为零则取到的日期中的天就会是当月的最后一天（比如，二月份就是28或29，其他月份就是30或31），方便下边的循环
      date.setDate(0);
      console.log("date.setDate(0);==>", date.setDate(0));
      let dayArry = [];
      //获取月份的某一天
      let day = date.getDate();
      console.log("day==>", day);
      for (let i = 1; i <= day; i++) {
        date.setDate(i); //如果只获取当前选择月份的每一天，则不需要date.setDate(i)只需dayArry.push(i)即可，其中date.setDate(i)是设置当前月份的每一天
        dayArry.push(
          // i + ' ' + this.getWeekday(date.getDay())
          {
            day: i,
            month: month + 1,
            weekNum: this.getWeekday(date.getDay()),
          }
        ); //选中月份的每一天和当天是星期几
      }
      this.everyDay = dayArry;
      console.log("dayArry==>", dayArry);
    },
    getWeekday(day) {
      return ["日", "一", "二", "三", "四", "五", "六"][day];
    },

    // 选择某一天课程
    chooseToday(row) {
      console.log("当前==>", row);
      this.nowToday = row.day;
      this.GetAllClassByTimeFn(row);
      this.GetAllClassMeByTimeFn(); //获取自己一天要上的课程
    },

    // 查看教案
    handleClose(done) {
      // this.$confirm("确认关闭？")
      // .then((_) => {
      this.fileList = [];
      done();
      // })
      // .catch((_) => {});
    },

    // 打开查看作业
    openLookWorkFn(row) {
      console.log("查看作业==>", row);
      this.drawer = true;
      this.WorkItem = row;
      this.WorkItem.PlanDetailInfo.MaterialUrls.forEach((item) => {
        let el = item.lastIndexOf("/");
        let els = item.slice(el + 1);
        this.fileList.push({ name: els, url: item });
      });
    },
    //预览
    Previewf(file) {
      console.log(file);
      // window.open(file.response)
      if (file) {
        const addTypeArray = file.name.split(".");
        const addType = addTypeArray[addTypeArray.length - 1];
        console.log(addType);
        if (addType === "pdf" || addType === "txt") {
          window.open(file.url);
        } else if (
          addType === "doc" ||
          addType === "docx" ||
          addType === "xls" ||
          addType === "excel" ||
          addType === "pptx"
        ) {
          let routeUrl = file.url;
          let url = encodeURIComponent(routeUrl);
          let officeUrl =
            "http://view.officeapps.live.com/op/view.aspx?src=" + url;
          window.open(officeUrl, "_target");
        } else if (["png", "jpg", "jpeg"].includes(addType)) {
          window.open(file.url);
        } else if (addType === "rar" || addType === "zip") {
          this.$message({
            message: "该文件类型暂不支持预览",
            type: "warning",
          });
          return false;
        }
      }
    },

    // 打印测试
    printHTML() {
      this.$print({
        printable: "printBox",
        type: "html",
        header: "打印标题",
        targetStyles: ["*"], // 打印内容使用所有HTML样式，没有设置这个属性/值，设置分页打印没有效果
      });
    },
    printJSON() {
      this.$print({
        printable: this.someJSONdata,
        properties: ["name", "email", "phone"],
        type: "json",
      });
    },
    printPDF() {
      this.$print({ printable: "docs/xx_large_printjs.pdf", type: "pdf" });
    },
    printImg() {
      this.$print({
        printable: this.WorkItem.PlanDetailInfo.HomeWorkUrl, // 也可设置清晰度比html绑定更高精度的图片
        type: "image",
        style: `@media print { @page {size: auto; margin: 0; } body{margin:0 5px}}`, //解决出现多页打印时第一页空白问题
      });
      // this.$print({
      // 	printable: [this.printUrl, this.printUrl2], // 打印多张图片
      //   type: 'image'
      // });
    },
    //打印多张
    printImg2() {
      this.$print({
        printable: this.WorkItem.PlanDetailInfo.PictureUrl, // 打印多张图片
        type: "image",
      });
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      // this.GetAllStoreFn(); //
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex = val;
      // this.GetAllStoreFn(); //
    },

    getWeektime1() {
      let myDate = new Date(); // 获取今天日期
      const dateArray = [];
      for (let i = 0; i < 7; i++) {
        dateArray.push(myDate.toLocaleDateString());
        myDate.setDate(myDate.getDate() - 1);
      }
      console.log("dateArray==>", dateArray);
      return dateArray;
    },
    getWeektime2() {
      let myDate = new Date();
      myDate.setDate(myDate.getDate() - 6);
      const dateArray = [];
      for (let i = 0; i < 7; i++) {
        dateArray.push(myDate.toLocaleDateString());
        myDate.setDate(myDate.getDate() + 1);
      }
      console.log("dateArray2==>", dateArray);
      return dateArray;
    },
    getWeektime3() {
      let monday = new Date();
      let sunday = new Date();
      const nowDay = new Date().getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },

    // 搜索时的时间 开始
    getWeektime4() {
      console.log("选择开始");
      let monday = new Date(this.form.StartTime);
      let sunday = new Date(this.form.StartTime);
      const nowDay = new Date(this.form.StartTime).getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },
    // 搜索时的时间 结束
    getWeektime5() {
      console.log("选择结束");
      let monday = new Date(this.form.EndTime);
      let sunday = new Date(this.form.EndTime);
      const nowDay = new Date(this.form.EndTime).getDay() || 7;
      monday.setDate(monday.getDate() - nowDay + 1);
      sunday.setDate(sunday.getDate() - nowDay + 7);
      console.log("monday==>", monday.toLocaleDateString());
      console.log("sunday==>", sunday.toLocaleDateString());
      this.form.monday = monday.toLocaleDateString();
      this.form.sunday = sunday.toLocaleDateString();
      return {
        monday: monday.toLocaleDateString(),
        sunday: sunday.toLocaleDateString(),
      };
    },

    weeks(currentTime) {
      var currentDate = new Date(currentTime);
      var timesStamp = currentDate.getTime();
      var currenDay = currentDate.getDay();
      // console.log("currenDay==>", currenDay);
      var dates = [];
      for (var i = 0; i < 7; i++) {
        dates.push(
          new Date(
            timesStamp + 24 * 60 * 60 * 1000 * (i - ((currenDay + 6) % 7))
          )
            .toLocaleDateString()
            .replace(/\//g, "-")
        );
      }
      console.log("dates==>", dates);
      this.form.StartTime = dates[0];
      this.form.EndTime = dates[6];
      this.dates = dates;
      let arr = this.AllWeekData;
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        element.date = dates[i];
        // console.log("element.date==>", new Date(dates[i]).getTime());
      }
      console.log("arr==>", arr);
      this.AllWeekData = arr;
    },

    // 表头
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#F8FCFE;color:#fff;font-size:16px;color:#333";
    },

    //打开添加课程
    openDialogVisible1() {
      this.dialogVisible1 = true;
    },
    //关闭添加课程
    offDialogVisible1() {
      this.dialogVisible1 = false;
    },

    //选择星期
    clickWeekFn(e) {
      this.weekIndex = e;
    },

    //查询小教案
    SearchSmallIdFn() {
      let that = this;
      let index = that.teachData.findIndex((item) => {
        return item.Id == that.form.BigTeachPlanId;
      });
      this.SmallTeachData = that.teachData[index].teachPlans;
    },

    // 搜索
    SearchFn() {
      this.searchCheck = true;
      this.GetAllClassByTimeFn();
    },
    //重置
    ResetFn() {
      this.searchCheck = false;
      let today = new Date();
      this.weeks(today);
      this.getWeektime3();

      let date = new Date();
      let month = date.getMonth();
      let nowToday = date.getDate();
      this.nowToday = nowToday;
      this.getEveryDay(date, month);
      this.month = month + 1;
      this.form.Classtype = "";
      this.form.StoreId = "";
      this.form.TeachId = "";
      this.GetAllClassByTimeFn({
        month: month + 1,
        day: nowToday,
        weekNum: "",
      });
    },

    //根据条件获取小教案 /api/PlanDetail/GetRealTeachPlanBySome
    GetRealTeachPlanBySomeFn() {
      let that = this;
      GetRealTeachPlanBySome({
        Year: that.form.Year === "" ? -1 : that.form.Year,
        Month: that.form.Month === "" ? -1 : that.form.Month,
        Week: that.form.Week === "" ? -1 : that.form.Week,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有小教案==>", res.Data);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.TeachPlanName =
              element.Year + "岁" + element.Month + "月" + element.Week + "周";
          }
          that.SmallTeachData = res.Data;
        }
      });
    },

    //教师自己申请课程
    AddExamineFn() {
      let that = this;
      console.log("教师自己申请课程==>", that.form);
      let StartHour = that.form.Time.split(":")[0];
      let StartMinute = that.form.Time.split(":")[1];
      let EndHour = that.form.Time2.split(":")[0];
      let EndMinute = that.form.Time2.split(":")[1];
      //加八个小时
      let miao = new Date(that.form.WorkTime).getTime() + 8 * 60 * 60 * 1000;
      console.log("miao==>", new Date(miao));
      // 转为时间
      that.form.WorkTime = new Date(miao);

      let inx = that.PlanTypeData.findIndex((item) => {
        return item.Id == that.form.WorkType;
      });
      let WorkTypeName = that.PlanTypeData[inx].label;
      console.log("WorkTypeName==>", WorkTypeName);
      // return
      AddExamine({
        StartHour: StartHour,
        StartMinute: StartMinute,
        EndHour: EndHour,
        EndMinute: EndMinute,
        WorkType: that.form.WorkType,
        WorkTypeName: WorkTypeName,
        WorkName: "-1",
        // Year: that.form.Year,
        // Month: that.form.Month,
        // Week: that.form.Week,
        Year: 0,
        Month: 0,
        Week: 0,
        // WorkTime: that.form.WorkTime,
        WorkTime: new Date(miao),
        WorkId: that.form.WorkId === "" ? "-1" : that.form.WorkId,
        StoreId: that.form.StoreId,
        StuNum: that.form.StuNum,
        TeachPlanId: that.form.TeachPlanId,
        StoreRoomName: that.form.StoreRoomName,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("教师自己申请课程==>", res.Data);
          that.$message({
            message: "已提交申请",
            type: "success",
          });
          that.dialogVisible1 = false;
          // that.form.Year = "";
          // that.form.Month = "";
          // that.form.Week = "";
          // that.form.WorkTime = "";
          // that.form.WorkType = "";
          // that.form.Date = "";
          // that.form.Time = "";
          // that.form.TeachPlanId = "";
          // that.form.BigTeachPlanId = "";
          // that.form.WorkId = "";
          // that.form.Time2 = "";
          // that.form.StoreRoomName = "";
          that.GetAllClassMeByTimeFn(); //获取自己一周要上的课程
        } else {
          that.$message({
            message: res.Message,
            type: "error",
          });
        }
      });
    },

    //获取所有教案 wuyong
    GetAllTeachPlanFn2() {
      let that = this;
      GetAllTeachPlan().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有教案==>", res.Data);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.TeachPlanName =
              element.Year + "岁" + element.Month + "月" + element.Week + "周";
          }
          that.teachData = res.Data;
        }
      });
    },

    //获取所有课程
    GetAllWorkFn() {
      let that = this;
      GetAllWork().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取所有课程==>", res.Data);
          that.courseData = res.Data;
        }
      });
    },

    //获取所有门店课室
    GetOwnStoreFn() {
      let that = this;
      GetOwnStore().then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取门店==>", res.Data);
          that.StoreData = res.Data;
        }
      });
    },

    //获取一周的课程
    GetAllClassByTimeFn() {
      let that = this;

      let time =
        new Date().getFullYear() + "-" + that.month + "-" + that.nowToday;

      GetAllClassByTime({
        SearchTime: time,
        Classtype:
          that.searchForm.Classtype == "" ? -1 : that.searchForm.Classtype,
        TeacherName: that.searchForm.TeachId,
        storeId: that.searchForm.StoreId == "" ? -1 : that.searchForm.StoreId,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取这一周课程==>", res.Data);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }

          that.ThisWeekData = res.Data;

          // if(res.Data.length==0){
          // console.log("StartTime==>", that.form.StartTime);
          // let today = new Date(that.form.StartTime);
          // that.weeks(today);
          // for (let h = 0; h < that.AllWeekData.length; h++) {
          //   const element = that.AllWeekData[h];
          //   element.courseData = [];
          // }
          // }

          console.log("that.dates==>", that.dates);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];

            element.myClassCheck = false; //是否是自己的课

            return;
          }
        }
      });
    },

    //废弃
    GetAllClassByTimeFn2() {
      let that = this;

      if (that.searchCheck == true) {
        that.getWeektime4();
        let today = new Date(that.form.StartTime);
        that.weeks(today);
      } else {
        let monday = new Date();
        let sunday = new Date();
        const nowDay = new Date().getDay() || 7;
        monday.setDate(monday.getDate() - nowDay + 1);
        sunday.setDate(sunday.getDate() - nowDay + 7);
        console.log("monday==>", monday.toLocaleDateString());
        console.log("sunday==>", sunday.toLocaleDateString());
        this.form.monday = monday.toLocaleDateString();
        this.form.sunday = sunday.toLocaleDateString();
      }
      GetAllClassByTime({
        StartTime: that.form.monday,
        EndTime: that.form.sunday,
        Classtype: that.form.Classtype == "全部" ? -1 : that.form.Classtype,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取这一周课程==>", res.Data);
          that.ThisWeekData = res.Data;

          // if(res.Data.length==0){
          console.log("StartTime==>", that.form.StartTime);
          let today = new Date(that.form.StartTime);
          that.weeks(today);
          for (let h = 0; h < that.AllWeekData.length; h++) {
            const element = that.AllWeekData[h];
            element.courseData = [];
          }
          // }

          console.log("that.dates==>", that.dates);
          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.myClassCheck = false; //是否是自己的课
            //获取教师id
            let teacherId = JSON.parse(localStorage.getItem("TokenData")).Id;
            console.log("teacherId==>", teacherId);
            if (element.TeacherId == teacherId) {
              element.myClassCheck = true;
            }

            // 只取年月日
            // let time = element.WorkTime = element.WorkTime.split(" ")[0];
            //每一个课程的时间戳
            let time = new Date(element.WorkTime.split(" ")[0]).getTime();
            // console.log("str==>",time);
            // console.log("str每个课程时间戳==>",new Date(time).getTime());
            // console.log("element每个课程时间戳==>", time);

            // 一周每天的时间戳
            for (let j = 0; j < that.dates.length; j++) {
              const element2 = that.dates[j];
              // console.log("星期几==>",element2);
              // console.log("element星期几时间戳==>",new Date(element2).getTime());
              //一天的时间戳是多少

              let dayTime = new Date(element2).getTime() + 86400000;
              // console.log("dayTime==>",dayTime);

              if (time > new Date(element2).getTime() && time < dayTime) {
                //根据that.AllWeekData[j].courseData中的StartHour进行从小到大排序

                that.AllWeekData[j].courseData.push(element);
                that.AllWeekData[j].courseData.sort(function (a, b) {
                  return a.StartHour - b.StartHour;
                });
              }
              // else{
              //   that.AllWeekData[j].courseData.push({});
              // }
            }
          }
        }
      });
    },

    //获取自己一周要上的课程
    GetAllClassMeByTimeFn() {
      let that = this;

      let time =
        new Date().getFullYear() + "-" + that.month + "-" + that.nowToday;

      // let monday = new Date();
      // let sunday = new Date();
      // const nowDay = new Date().getDay() || 7;
      // monday.setDate(monday.getDate() - nowDay + 1);
      // sunday.setDate(sunday.getDate() - nowDay + 7);
      // console.log("monday==>", monday.toLocaleDateString());
      // console.log("sunday==>", sunday.toLocaleDateString());
      // this.form.monday = monday.toLocaleDateString();
      // this.form.sunday = sunday.toLocaleDateString();

      GetAllClassMeByTime({
        // StartTime: that.form.monday,
        // EndTime: that.form.sunday,
        SearchTime: time,
      }).then((res) => {
        console.log(res);
        if (res.Code == 2000) {
          console.log("获取自己一周要上的课程==>", res.Data);

          for (let i = 0; i < res.Data.length; i++) {
            const element = res.Data[i];
            element.WorkTime =
              element.WorkTime.split(" ")[0] +
              " " +
              String(
                String(
                  element.StartHour < 10
                    ? "0" + element.StartHour
                    : element.StartHour
                ) +
                  ":" +
                  String(
                    element.StartMinute < 10
                      ? "0" + element.StartMinute
                      : element.StartMinute
                  ) +
                  "-" +
                  String(
                    element.EndHour < 10
                      ? "0" + element.EndHour
                      : element.EndHour
                  ) +
                  ":" +
                  String(
                    element.EndMinute < 10
                      ? "0" + element.EndMinute
                      : element.EndMinute
                  )
              );
          }

          that.MineWeekData = res.Data;
          that.AllCount = res.Data.length;

          console.log("MineWeekData==>", that.MineWeekData);
        }
      });
    },

    // vnc token

    copyVNCToken() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.VNCToken);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.CopySuccessMessage();
    },
    // token
    copyToken() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.token);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.CopySuccessMessage();
    },

    CopySuccessMessage() {
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    SuccessMessage() {
      this.$message({
        message: "刷新成功",
        type: "success",
      });
    },
    ErrorMessage() {
      this.$message({
        message: "刷新失败",
        type: "error",
      });
    },
  },
};
</script>

<style lang="less" scoped>
//主盒子
.indexBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

// .课程表
.coursebox {
  max-width: 150px;
  height: 100px;
  text-align: center;
  // padding: 20px 10px 0 10px;
  padding: 20px 0px 0 0px;
  box-sizing: border-box;
  // margin-left: 10px;
  // margin-right: 20px;
  margin-top: 10px;
  background-color: #f9f9f9;
}

//小格子
.box-property {
  width: 280px;
  height: 70px;
  box-shadow: 0px 2px 2px #d0d0d0;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
}

//图片属性
.img-property {
  width: 70px;
  float: left;
}

//文字属性
.text-property {
  font-size: 20px;
  margin-left: 30px;
}

//数据文字属性
.text-data {
  font-size: 20px;
  margin-left: 40px;
  color: dodgerblue;
}

/* 鼠标移入表格不高亮 */

.table-tranparent.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}

.table-tranparent.el-table tbody tr:hover {
  background-color: #ffffff !important;
}

@page {
  margin: 0;
}
@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 3cm;
  }
}
</style>

<style type="text/css">
@page {
  margin-top: 3mm;
  margin-bottom: 3mm;
}
/* @page { margin: 0; }
@page { margin: 0; }  */
@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 3cm;
  }
}
</style>
